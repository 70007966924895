import React from "react";
import Layout from "./layout";

function PrivacyPolicy() {
  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen">
        <div className="max-w-4xl mx-auto px-4 py-8">
          <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
          <p className="mb-4">
            At Expertly, we are dedicated to safeguarding your privacy and
            providing you with a secure online experience. This privacy
            statement outlines our data collection and usage practices. By
            accessing this site, you are agreeing to the terms outlined in this
            Privacy Policy. Please note that this policy does not supersede any
            existing agreements directly established between you and Expertly.
            This Privacy Policy also outlines our data storage practices,
            including how your information will be stored in the United States,
            the European Economic Area (EEA), and other locations outside the
            EEA. Rest assured that any transfer of your data to the United
            States or any other location outside the EEA will be conducted in
            accordance with relevant European data protection laws. In
            particular, for transfers to the United States, we ensure compliance
            with the E.U.-U.S. Privacy Shield Framework. More information about
            the Privacy Shield Framework can be found below.
          </p>
          <p className="mb-4">
            This Privacy Policy also outlines our data storage practices,
            including how your information will be stored in the United States,
            the European Economic Area (EEA), and other locations outside the
            EEA. Rest assured that any transfer of your data to the United
            States or any other location outside the EEA will be conducted in
            accordance with relevant European data protection laws. In
            particular, for transfers to the United States, we ensure compliance
            with the E.U.-U.S. Privacy Shield Framework. More information about
            the Privacy Shield Framework can be found below.
          </p>
          <p className="font-semibold mb-2">Gathering Personal Data</p>
          <p className="mb-4">
            At Expertly, we will only request personal information when it is
            necessary to identify you or to facilitate contact with you. This
            typically occurs when you are subscribing to email newsletters,
            accessing premium limited-content sites, or making purchases. The
            personal information we collect usually includes your name, email
            address, phone number, country or location, and may extend to
            additional details required to fulfill specific requests or orders.
          </p>
          <p className="mb-4">
            In the event that Expertly invites you to complete and submit an
            online form, your participation is entirely optional. However,
            should you choose to participate, we may request personal details
            such as your first and last name, mailing address (including ZIP
            code), email address, employer, job title, department, telephone and
            fax numbers, and other relevant information. Moreover, when
            purchasing products or services, you may be prompted to provide a
            credit card number. Please note that for certain activities,
            specific information may be marked as mandatory, while other details
            are deemed voluntary. Failure to supply mandatory data for a
            particular activity will result in the inability to proceed with
            said activity.
          </p>
          <p className="mb-4">
            Upon the purchase and installation of new products, we may request
            electronic registration. This registration information is stored
            alongside any previously provided details from your previous visits
            to our website, forming your personal profile.
          </p>
          <p className="mb-4">
            Our website may utilize technology to collect technical information
            such as IP addresses, browser types, traffic patterns, and the
            originating addresses of any referring websites. Furthermore, we may
            use HTTP cookies for system administration. If you do not wish to
            receive cookies or prefer to receive notifications when they are
            utilized, you can configure your web browser accordingly, if
            supported.
          </p>
          <p className="mb-4">
            It's important to note that while Expertly may track a visitor's
            arrival at the website, we do not and will not gather information
            about the other sites you have visited. Additionally, we do not link
            IP addresses to personally identifiable details.
          </p>
          <p className="font-semibold mb-2">Utilization of Personal Data</p>
          <p className="mb-4">
            The purposes for which Expertly may utilize your personal
            information are as follows:
          </p>
          <p className="mb-4">
            Streamlining your site experience by requesting your personal
            information only once.
          </p>
          <p className="mb-4">
            Providing relevant content creation and publication based on
            customer preferences.
          </p>
          <p className="mb-4">
            Notifying customers about product upgrades, special offers, updated
            information, and new products and services.
          </p>
          <p className="mb-4">
            If you wish to be removed from any Expertly distribution list,
            simply click on the provided link within each electronic
            communication or reply to the email with the subject line
            "unsubscribe".
          </p>
          <p className="mb-4">
            In the event that you have provided your telephone number to
            Expertly, you may receive telephone calls from us regarding new
            products, services, or upcoming events. If you prefer not to receive
            such calls, please inform us.
          </p>

          <p className="font-semibold mb-2">Connecting to External Websites</p>
          <p className="mb-4">
            The Expertly website may contain links to external websites,
            including partner websites. It is advisable to review the privacy
            policies and other guidelines of these third-party sites, as they
            are not governed by Expertly's privacy policy. Expertly has no
            control over the content or information gathering practices of these
            external sites.
          </p>
          <p className="mb-4">
            Please note that this Privacy Policy specifically pertains to
            Expertly's privacy practices and does not extend to the privacy
            policies or practices of third-party websites or features. Expertly
            cannot be held responsible for the privacy policies and/or practices
            of third parties.
          </p>
          <p className="font-semibold mb-2">Legal Compliance</p>
          <p className="mb-4">
            We may access and disclose your information if we reasonably believe
            that such action is necessary to:
          </p>
          <p className="mb-4">
            <ol style={{ listStyleType: "lower-alpha" }} className="pl-6 mb-4">
              <li>uphold legal rights and abide by the law;</li>
              <li>
                comply with directives from government entities or authorized
                authorities;
              </li>
              <li>
                prevent or address potential or actual harm or interference with
                the rights, property, operations, users, or any individuals
                associated with Expertly;
              </li>
              <li>
                safeguard our rights, prevent fraudulent activities, and comply
                with court orders, judicial proceedings, or legal processes
                served on Expertly.
              </li>
            </ol>
          </p>
          <p className="font-semibold mb-2">Law Enforcement</p>
          <p className="mb-4">
            In some cases, we may have to disclose your information to
            government or law enforcement officials if required by law or to
            comply with legal obligations such as national security or law
            enforcement requirements. Additionally, we may disclose your
            information to enforce our terms and conditions, respond to claims,
            protect our rights or those of a third party, ensure safety of any
            person, or prevent illegal activities including for the detection
            and prevention of fraud or credit risk.
          </p>
          <p className="mb-4">
            Please note that you will be notified when any third party collects
            or shares identifying information about you, excluding our service
            providers, so you can make an informed decision on whether or not to
            share your information with such third party. If you do not wish to
            have your information shared and want to opt-out of receiving
            communications from any third party that is not our agent or service
            provider, please contact the third party directly.
          </p>
          <p className="font-semibold mb-2">Security</p>
          <p className="mb-4">
            Ensuring the security and confidentiality of your information is
            paramount at Expertly. We have implemented robust measures to
            safeguard your privacy and prevent unauthorized access, misuse, or
            alteration of your data.
          </p>
          <p className="mb-4">
            Upon submission, your information is securely stored behind advanced
            firewall protection. Our dedicated team is fully trained and adheres
            to strict privacy and security protocols, ensuring that access to
            your information is limited to authorized personnel only.
          </p>
          <p className="mb-4">
            At Expertly, we remain committed to upholding the highest standards
            of data protection, providing you with peace of mind as you engage
            with our services.
          </p>
          <p className="font-semibold mb-2">EU-US Privacy Shield</p>
          <p className="mb-4">
            For individuals residing in the European Economic Area (EEA),
            Expertly remains fully compliant with the EU-US Privacy Shield
            framework established by the United States Department of Commerce.
            This framework governs the collection, retention, and use of
            personal data from EEA countries, ensuring adherence to stringent
            privacy standards
          </p>
          <p className="mb-4">
            As part of our commitment to the Privacy Shield principles, we
            uphold the values of transparency, accountability, and data
            integrity. This includes providing notice to individuals about the
            collection and use of their personal data, offering choices
            regarding its processing, and implementing robust security measures
            to safeguard against unauthorized access or disclosure.
          </p>
          <p className="mb-4">
            It's important for visitors outside the US to understand that by
            accessing our website, they are consenting to the transfer of their
            information to the US, where our servers are located. Depending on
            the nature of the data and its storage methods, this information may
            also be transferred to other countries. While these countries may
            have differing data protection laws, our privacy policy remains
            consistent in governing the usage, storage, and collection of
            personal data.
          </p>
          <p className="mb-4">
            At Expertly, we are committed to maintaining the highest standards
            of privacy protection and will continue to uphold the principles
            outlined in our privacy policy, regardless of jurisdiction. Your
            trust and confidence in our handling of your personal data are of
            paramount importance to us.
          </p>
          <p className="font-semibold mb-2">
            Responsibility and Accountability for Subsequent Transfers
          </p>
          <p className="mb-4">
            Expertly is mandated to adhere to specific procedures when
            transferring personal data received from the European Union to third
            parties. This includes incorporating contractual provisions into our
            third-party agreements, obligating them to uphold the same level of
            protection mandated by the Privacy Shield and restricting their use
            of the data solely to the specified services provided on our behalf.
          </p>
          <p className="mb-4">
            We take diligent measures to ensure that third parties handle
            personal data in alignment with our Privacy Shield obligations.
            Should any unauthorized processing occur, we promptly intervene and
            take appropriate remedial action.
          </p>
          <p className="mb-4">
            In certain instances, we may retain liability for the actions of
            third parties who conduct services on our behalf, particularly
            concerning their management of personal data transferred to them
            under the Privacy Shield framework.
          </p>
          <p className="font-semibold mb-2">
            Privacy Complaints Resolution, Remediation, and Enforcement
          </p>
          <p className="mb-4">
            In accordance with the Privacy Shield Principles, Expertly is
            dedicated to addressing and resolving complaints concerning your
            privacy and the collection or usage of your personal information, at
            no expense to you. European Union individuals seeking clarification
            or raising concerns about this Privacy Policy are encouraged to
            initially contact us.
          </p>
          <p className="mb-4">
            We are committed to promptly addressing all complaints and aim to
            provide a response within 45 days. Additionally, you have the option
            to escalate your complaint to your local data protection authority,
            and we will collaborate with them to address your concerns
            effectively
          </p>
          <p className="mb-4">
            It's important to note that if your complaint remains unresolved
            through these channels, there may be a binding arbitration option
            available under certain limited circumstances. This option requires
            that you: (1) directly raise your complaint with us using the
            contact information provided above, allowing us the opportunity to
            resolve the issue, and (2) escalate the matter through the relevant
            data protection authority, affording the U.S. Department of Commerce
            the opportunity to address the complaint without any cost to you.
          </p>
          <p className="mb-4">
            Expertly is subject to the investigative and enforcement authority
            of the Federal Trade Commission in cases of non-compliance with the
            Privacy Shield.
          </p>
          <p className="font-semibold mb-2">Access to information</p>
          <p className="mb-4">
            You possess the entitlement to access the personal data we gather
            about you in the EEA, as well as the privilege to request
            rectification, modification, or deletion if it proves inaccurate or
            is processed in breach of the Privacy Shield. It's important to
            acknowledge that these access rights may not always be applicable,
            particularly in instances where we are compelled to adhere to legal
            obligations or where facilitating access is excessively cumbersome
            or costly given the circumstances, or if it would contravene the
            rights of individuals other than the requester.
          </p>
          <p className="mb-4">
            Should you wish to exercise your rights to access, rectify, or
            delete your personal data collected in the EEA, please submit a
            written request using the contact details provided below. To
            authenticate your identity, we may request specific information from
            you. In certain situations, a reasonable fee may be applicable for
            accessing your information.
          </p>
          <p className="font-semibold mb-2">
            Customer Data Processing and GDPR Compliance
          </p>
          <p className="mb-4">
            In the course of delivering our services and solutions to our
            customers, we handle personal information solely for the purpose of
            supporting and delivering those solutions. As entrusted data
            processors, we exclusively collect, process, and store personal
            information to facilitate the provision of services to our
            customers. We uphold strict adherence to this principle, ensuring
            that the information entrusted to us is never utilized for our own
            purposes.
          </p>
          <p className="font-semibold mb-2">Modifications</p>
          <p className="mb-4">
            Expertly reserves the right to modify or supplement this Privacy
            Policy at its discretion. We encourage you to periodically review
            this section of Expertly’s website to stay informed of any updates.
            In the event of material changes to this Privacy Policy, we will
            notify you through this platform, via email, or by posting a notice
            on our homepage.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
